import React, { useCallback, useMemo, useState } from "react";
import UserProfileIcon from "./UserProfileIcon";
import Menu, { Item } from 'devextreme-react/menu';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import { CookieStorage } from 'cookie-storage';
const cookies = new CookieStorage();

export interface IUserProfileMenu {
    title?: string;
    icon?: string | React.ReactNode;
    onClickHandler?: (args: any) => void;

};

export type UserProfileMenu = Array<IUserProfileMenu>;

interface IUserMenuProps {
    title?: string;
    menuList?: UserProfileMenu;
    onHomeClick?: () => void;
    onLogoutClick?: () => void;
    onChangePasswordClick?: () => void;

}

function UserMenu({ title, onHomeClick, onLogoutClick, onChangePasswordClick }: IUserMenuProps) {

    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const fullName = cookies.getItem('firstname') + " " + cookies.getItem('lastname');

    const onHome = useCallback(() => {
        if (onHomeClick) {
            onHomeClick();
        }
    },[onHomeClick]);

    const onLogout = useCallback(() => {
        if (onLogoutClick) {
            onLogoutClick()
        }
    },[onLogoutClick]);

    const onChangePassword = useCallback(() => {
        setOpenMenu(false);
        if (onChangePasswordClick) {
            onChangePasswordClick()
        }
    },[onChangePasswordClick]);

    const menuItems = useMemo(() => ([
        {
            text: 'Home',
            icon: 'home',
            onClick: onHome
        },
        {
            text: fullName,
            icon: 'user',
            onClick: () => { }
        },
        {
            text: 'Logout',
            icon: 'unlock',
            onClick: onLogout
        },
        {
            text: 'Change Password',
            icon: 'key',
            onClick: onChangePassword
        }
        ,
        {
            text: 'Release: Version 2.5.1',
            icon: 'runner',
            onClick: () => { }
        }
    ]), []);


    const onItemClick = useCallback((e: any) => {
        const selectedItem = menuItems[e.itemIndex];
        if (selectedItem && selectedItem.onClick) {
            selectedItem.onClick();
        }
    },[menuItems]);

    const iconClickHandler = useCallback(() => {
        setOpenMenu(true);
    },[]);

    const onHidden = useCallback(()=>setOpenMenu(false),[]);

    return (
        <>
            <UserProfileIcon onClickHandler={iconClickHandler} className="userProfileMenuIcon" title={title} />
            <ContextMenu
                items={menuItems}
                target={'.userProfileMenuIcon'}
                width={210}
                cssClass={'user-menu'}
                onItemClick={onItemClick}
                hideOnOutsideClick
                onHidden={onHidden}
                visible={openMenu}
                
            >
                <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
            </ContextMenu>
        </>
    )
}
export default UserMenu; 