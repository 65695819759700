import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import "./assets/sass/styles.scss";
import "./assets/themes/default/style.scss";
import "./variable.css";
import './styles.scss';
import AppRoute from './app.route';
import { setCurrentUser, logoutUser } from '../src/app/redux/actions/authActions';
import { clearCurrentProfile } from '../src/app/redux/actions/profileActions';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './app/shared/components/loader/loader';


const App: React.FC<AppState> = (props: AppState) => {
  return (
    <>
      <Router>
        {AppRoute}
        <ToastContainer />
      </Router>
      <Loader loadPanelVisible={props.isLoaderShow} />
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isLoaderShow: state.appLoaderReducer.isLoaderShow
  }
}

export default connect(mapStateToProps)(App);

interface AppState {
  isLoaderShow: boolean
}

