import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { AxiosError, AxiosResponse } from 'axios';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Formik
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import moment from 'moment';


import Sharing from '../../../../shared/components/commonui/Sharing'
import ModalBox from '../../../../shared/components/commonui/Modal/ModalBox';
import Button from '../../../../shared/components/commonui/Button';
import SelectWithLabel from '../../../../shared/components/commonui/HigherOrderComponents/SelectWithLabel';
import InputWithLabel from '../../../../shared/components/commonui/HigherOrderComponents/InputWithLabel';
import { FormDateBox } from '../../../../shared/components/commonui/HigherOrderComponents/form-datebox/FormDateBox';


import { getDataSharingContracts, updateDataSharingStatus, addDataSharing, getUserGeneratedETIds } from '../../../../api/dataSharing/Contracts';
import { toggleLoader } from '../../../../redux/actions/loader.action';
import Toaster from '../../../../services/toaster.services';


//constants
import appConstants from '../../../../core/constants/appConstants';
import { getContracts } from '../../../../api/dataSharing/Contracts';


// SCSS
import './DataSharingTable.scss'


function DataSharingTable(props: IDataSharingProps) {
  const [dataContractList, setDataContractList] = useState<any>([]);
  const [orderIndexedFields, setOrderIndexedFields] = useState([{ 'orderBy': 'signedAt', 'order': 'desc', 'isActive': false }]);
  const [totalRecord1, setTotalRecord1] = useState<number>(0);
  const [pageSize1, setPageSize1] = useState<number>(10);
  const [totalPage1, setTotalPage1] = useState<number>(1);
  const [currentPageNo1, setCurrentPageNo1] = useState<number>(1);
  const [order, setOrder] = useState<number>(-1);
  const [orderBy, setOrderBy] = useState<string>("CreatedAt");
  const [nextPage1, setNextPage1] = useState();
  const [prePage1, setPrePage1] = useState<any>();
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [viewCredentialApp, setViewCredntialApp] = useState<boolean>(true);
  const [allORGList, setAllORGList] = useState<any>([]);
  const [userGeneratedETIds, setUserGeneratedETIds] = useState<any>([]);
  const [sharingInitialVal, setSharingInitialVal] = useState<any>({ ORGName: props.contractDetails?.ORGName, UID: props.contractDetails?.UID, ETId: '', StartDate: null, EndDate: null });

  const validationDataSharing = yup.object().shape({
    ORGName: yup.string().required('Organization Name is required'),
    UID: yup.string().required('Contract Id is required'),
    ETId: yup.string().required('ETId is required')
  });

  // Sorting Data
  const onSortingData = useCallback((orderBy: any, order: any, data: any) => {
    if (orderBy && order && data) {
      setOrder(order === 'desc' ? -1 : 1);
      setOrderBy(orderBy);
      setOrderIndexedFields(data);
    }
  }, [])

  const initializeComponent = () => {
    props.toggleLoader(true);
    Promise.all([getUserGeneratedETIds({}), getContracts({ "IsDefaultOrg": false })]).then((responseList: any) => {
      if (responseList.length > 0) {
        //To Do, add dropdown to choose All SV;
        const filteredSVData = responseList[0]?.data?.data?.filter((obj: any) => obj.SV === 1);
        setUserGeneratedETIds(filteredSVData);
        setAllORGList(responseList[1]?.data?.data);
      }
      props.toggleLoader(false);
    }).catch((errorList: any) => {
      props.toggleLoader(false);
      console.log(errorList, "values")
    })
  }

  //
  useEffect(() => {
    initializeComponent();
  }, []);

  // Previous Page
  const goToPreviousPage1 = useCallback((page: any) => {
    setCurrentPageNo1(page);
  }, [])

  useEffect(() => {
    if (prePage1) {
      goToPreviousPage1(prePage1);
    }
  }, [prePage1]);

  // Goto Next Page
  const goToNextPage1 = useCallback((page: any) => {
    setCurrentPageNo1(page);
  }, [])

  useEffect(() => {
    if (nextPage1) {
      goToNextPage1(nextPage1);
    }
  }, [nextPage1]);

  // Change Page Size
  const changePageSize1 = useCallback((value: any) => {
    setPageSize1(value);
    setCurrentPageNo1(1)
  }, [])

  useEffect(() => {
    if (pageSize1) {
      changePageSize1(pageSize1);
    }
  }, [pageSize1]);

  // IsLessThenElement
  const isLessthanTotalElement1 = useCallback(() => {
    if ((currentPageNo1 * pageSize1) === (totalPage1 - 1))
      return true;
    else
      return false;
  }, [currentPageNo1, pageSize1, totalPage1])

  // Status Formatter
  const statusTextFormatter = (essObject: any) => {
    const statusToShortForm = {
      10: appConstants.CONTRACT_STATUS.SH_ETId_Submitted.shortForm,
      20: appConstants.CONTRACT_STATUS.SH_ETId_SenderApproved.shortForm,
      30: appConstants.CONTRACT_STATUS.SH_ETId_SenderApprovedSentToReceiver.shortForm,
      40: appConstants.CONTRACT_STATUS.SH_ETId_ReceiverApproved.shortForm,
      50: appConstants.CONTRACT_STATUS.SH_ETId_ReceiverSent.shortForm,
      60: appConstants.CONTRACT_STATUS.SH_ETId_DataSharing.shortForm
    }
    return (<span>{statusToShortForm[essObject?.data?.ESS]}</span>)
  }

  // Start Date
  const StartDateFormate = useCallback((item: any) => {
    return (
      (!item.data.StartDate) ? <span>N.A</span> : <span>{moment.utc(item.data.StartDate).format('YYYY-MM-DD HH:mm:ss')}</span>
    )
  },[])

  // End Date
  const EndDateFormate = useCallback((item: any) => {
    return (
      (!item.data.EndDate) ? <span>N.A</span> : <span>{moment.utc(item.data.endDate).format('YYYY-MM-DD HH:mm:ss')}</span>
    )
  },[])


  // Get Sharing Data
  const getDataSharingContract = (query: any) => {
    props.toggleLoader(true);
    getDataSharingContracts(query).then(res => {
      props.toggleLoader(false);
      setDataContractList(res.data.data);
      setTotalRecord1(res.data.total);
      if (parseInt(res.data.total) % pageSize1 === 0) {
        setTotalPage1((parseInt(res.data.total) / pageSize1));
      }
      else {
        setTotalPage1((Math.floor(parseInt(res.data.total) / pageSize1)) + 1);
      }
      props.toggleLoader(false);
    }).catch(error => {
      props.toggleLoader(false);
      console.log(error, "Error data")
    })
  }

  useEffect(() => {
    if (pageSize1 && currentPageNo1) {
      getDataSharingContract({ pageNo: currentPageNo1, pageSize: pageSize1, IsDefaultOrg: false })
    }
  }, [pageSize1, currentPageNo1, forceUpdate])


  // Approve Handler
  const approveDataSharingHandler = (dataObject: any) => {
    const STATUS = appConstants.CONTRACT_STATUS
    if (dataObject.ESS < 60) {
      updateSharingApprovalStatus(dataObject, dataObject.ESS + 10);
    }
  };


  // Update Status
  const updateSharingApprovalStatus = (dataObject: any, newStatusToUpdate: number) => {
    const updateStatusBody = [{ "UID": dataObject.DataSharing22UID, ESS: newStatusToUpdate }];
    props.toggleLoader(true);
    updateDataSharingStatus(updateStatusBody).then((result: AxiosResponse) => {
      Toaster.successToaster("updateSharingApprovalStatus", "Approved");
      setForceUpdate((prev) => !prev);
      props.toggleLoader(false);
    }).catch((error: AxiosError | Error) => {
      props.toggleLoader(false);
      if (typeof error.message == "string") {
        Toaster.errorToaster(error.message);
      }
    });
  }

  const createDataSharingContract = async (dataObject: any, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.resetForm();
    props.toggleLoader(true);
    const dataSharingBody = [{
      SharedETId: dataObject["ETId"],
      ESS: appConstants.CONTRACT_STATUS.SH_ETId_Submitted.code,
      SharedContractId: dataObject["UID"],
      StartDate: dataObject["StartDate"],
      EndDate: dataObject["EndDate"]
    }]
    addDataSharing(dataSharingBody).then((createResult: AxiosResponse) => {
      if (createResult.status = 200) {
        Toaster.successToaster("createOrganizationAndContract", "Contact Crated");
        setForceUpdate((prev) => !prev);
      }
      setViewCredntialApp(false);
      props.toggleLoader(false);

    }).catch((error) => {
      props.toggleLoader(false);
      setViewCredntialApp(false);
      if (error?.response?.data?.errors?.length > 0) {
        for (let i = 0; i < error.response.data.errors.length; i++) {
          Toaster.errorToaster(error.response.data.errors[i]?.message);
          break;
        }
      }
    })
  }

  const shareDataHandler = (contractDetails: any) => {
    setSharingInitialVal((prevValues: any) => {
      return { ...prevValues, ORGName: contractDetails.ORGName, UID: contractDetails.UID, }
    });
  }

  const tabColumnList = useMemo(() => [
    { name: "Contract Id", db: "UID" },
    { name: "Organization Name", db: "ORGName" },
    { name: "ETId", db: "SharedETId", },
    { name: "Total Number Of Records", db: "sender" },
    { name: "Total No. Of Shared Records", db: "status" },
    { name: "Start Date", db: "StartDate", cellRender: StartDateFormate },
    { name: "End Date", db: "EndDate", cellRender: EndDateFormate },
    { name: "Status", db: "ESS", cellRender: statusTextFormatter }
  ], []);

  const handleShareVisiblePopup = useCallback(() => {
    setViewCredntialApp(false)
  }, [])
  return (
    <>
      <Sharing
        dataSource={dataContractList}
        columnList={tabColumnList}
        primaryColumn={"_id"}
        showEdit={false}
        showDelete={false}
        showView={false}
        showActions={true}
        keysToHide={['']}
        showUserEdit={true}
        showAdd={true}
        showPagination={true}
        isOrderIconChange={true}
        columnAutoWidth={true}
        fromComponent={'UserComponent'}
        orderIndexedFields={orderIndexedFields}
        onSortingData={onSortingData}
        goToPreviousPage={goToPreviousPage1}
        goToNextPage={goToNextPage1}
        changePageSize={changePageSize1}
        pageSize={pageSize1}
        currentPageNo={currentPageNo1}
        totalPage={totalPage1}
        totalRecord={totalRecord1}
        createContract={true}
        shareData={true}
        isLessthanTotalElement={isLessthanTotalElement1()}
        viewCredentialApprove={() => { }}
        viewCredentialApproveData={approveDataSharingHandler}
      />
      <div className="create-shared-modal">
        <ModalBox
          visible={viewCredentialApp}
          onHiding={handleShareVisiblePopup}
          showCloseButton={true}
          dragEnabled
          showTitle={true}
          title="Share Data"
          onHidden={handleShareVisiblePopup}
          height={'auto'}
          width={500}
          wrapperAttr={{ class: 'create-share-details' }}
        >
          <Formik
            initialValues={sharingInitialVal}
            validationSchema={validationDataSharing}
            onSubmit={createDataSharingContract}
          >
            {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }) => (

              <form className="dx-form">
                <SelectWithLabel
                  inputLabel='Organization Name'
                  placeholder="Select Here"
                  stylingMode='outlined'
                  dataSource={allORGList}
                  name={'UID'}
                  displayExpr="ORGName"
                  valueExpr="UID"
                  value={values['UID']}
                  onItemClick={(event) => {
                    setFieldValue("UID", event.itemData.UID)
                  }}
                  error={touched.ORGName && errors.ORGName}
                  touched={touched.ORGName}
                />
                <InputWithLabel
                  inputLabel='Contract Id'
                  placeholder="Auto Fill"
                  stylingMode='outlined'
                  value={values["UID"]}
                  name="UID"
                  disabled
                />
                <SelectWithLabel
                  inputLabel='ETId'
                  placeholder="Select ETId"
                  stylingMode='outlined'
                  dataSource={userGeneratedETIds}
                  name={'ETId'}
                  displayExpr="ETId"
                  valueExpr="ETId"
                  value={values['ETId']}
                  onItemClick={(event) => {
                    setFieldValue("ETId", event.itemData.ETId)
                  }}
                  error={touched.ETId && errors.ETId}
                  touched={touched.ETId}
                />

                <FormDateBox
                  inputLabel='Start Date'
                  placeholder='Select Date'
                  value={values["StartDate"]}
                  name="StartDate"
                  onFocusOut={(e: any) => { handleBlur(e.event) }}
                  onValueChange={(event: any) => {
                    const selectedDate = event ? new Date(event) : null;
                    const milliseconds = selectedDate ? selectedDate.getTime() : null;
                    setFieldValue('StartDate', milliseconds)
                  }}
                  error={touched.StartDate && errors.StartDate}
                  touched={touched.StartDate}
                />

                <FormDateBox
                  inputLabel='End Date'
                  placeholder='Select Date'
                  value={values["EndDate"]}
                  name="EndDate"
                  onFocusOut={(e: any) => { handleBlur(e.event) }}
                  onValueChange={(event: any) => {
                    const selectedDate = event ? new Date(event) : null;
                    const milliseconds = selectedDate ? selectedDate.getTime() : null;
                    setFieldValue('EndDate', milliseconds)
                  }}
                  error={touched.EndDate && errors.EndDate}
                  touched={touched.EndDate}
                />
                <div className='data-share-btn'>
                  <Button text="Close" className="btn-close" onClick={handleShareVisiblePopup} />
                  <Button text="Create" type="default" className="btn-create" onClick={(e: any) => handleSubmit()} />
                </div>
              </form>
            )}
          </Formik>
        </ModalBox>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
  bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataSharingTable);

interface IDataSharingProps {
  toggleLoader: any;
  contractDetails: any;
}

