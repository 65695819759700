import React from 'react'
import Button from '../Button/Button'
import InputBox from '../Input/InputBox'
import { trimSpaces } from '../../../../utils/validation/common.validation'
import ThemeSwitcher from '../ThemeSwitcher'
import { Logout } from '../../../../../assets/svg'
import { Tooltip } from 'devextreme-react/tooltip'

interface HeaderProps {
    isLogin,
    userName,
    userFullName,
    password,
    authMessage,
    onSubmit,
    onLogout,
    setLogin,
    setUserName,
    setPassword,
    ContentSignOut
}
export default function CustomHeader(props: HeaderProps) {
    return (
        <div className='header'>
            <div className='header-titles'>
                <div className='header-section'>
                    <div className='with-login'>
                        <span className='logos'>
                            <img src={require('../../../../../assets/images/Walacor_Logo2.png')} className="walacorLogo" alt="logo" />
                        </span>
                    </div>

                    {!props.isLogin && <div className='sign-btn'>
                        {
                            props.userName != null && props.userName != "" ? <div className="logodiv">
                                <div className='separator' />
                                <span><div className="user-name">{"Welcome " + "" + props.userFullName}</div></span></div> :
                                <Button
                                    className="back-button-signInButton"
                                    icon="user"
                                    color="#ffffff"
                                    backgroundColor="#e63737"
                                    text="Sign-in"
                                    onClick={props.setLogin}
                                />
                        }
                    </div>
                    }
                    {
                        props.isLogin && <div className='loginForm'>
                            <div className="username-input-box">
                                <span className="label">Username/Email</span>
                                <InputBox
                                    className='dx-theme-background-color dx-theme-text-color'
                                    stylingMode="outlined"
                                    value={props.userName}
                                    onInput={props.setUserName}
                                />
                            </div>

                            <div className="username-input-box">
                                <span className="label">Password</span>
                                <InputBox
                                    stylingMode="outlined"
                                    className='dx-theme-background-color dx-theme-text-color'
                                    value={props.password}
                                    onInput={props.setPassword}
                                    mode='password'
                                />
                            </div>

                            <Button
                                className="username-input-box back-button-signInButton"
                                color="#ffffff"
                                backgroundColor="#e63737"
                                text="Submit"
                                onClick={props.onSubmit}
                            />
                            <div className="account-lock">
                                <span>{props.authMessage}</span>
                            </div>
                        </div>
                    }
                    <div className='select-theme'>
                        <ThemeSwitcher />
                        <div className="sign-out" style={{ cursor: "pointer" }} onClick={props.onLogout}>
                            <Logout id={'logout'} />
                            <Tooltip
                                target="#logout"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                contentRender={props.ContentSignOut}
                                position="bottom"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
