import React, { useEffect, useMemo, useCallback } from 'react';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleLoader } from '../../redux/actions/loader.action';

// MUI
import { Button, Tooltip, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Close, Delete, Create, InsertDriveFile, Visibility } from '@material-ui/icons';

// Services
import Toaster from '../../services/toaster.services';
import RestApiService from '../../services/http-services';

// Api
import { ApiEndPoints } from '../../models/api-endpoint';

// Formik
import { Formik, FieldArray } from 'formik';
import * as yup from 'yup';

// Packages
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

// Axios
import { AxiosResponse } from 'axios';

// Common Component
import ModalBox from '../../shared/components/commonui/Modal/ModalBox';
import DynamicTableList from '../../shared/components/commonui/DynamicTableList/DynamicTableList';
import NewSubHeaderComponent from '../../shared/components/new-sub-header/new-sub-header';

// Devextreme
import { ScrollView } from 'devextreme-react';

// Scss
import "./BPM.scss";
import "../../../assets/themes/default/share.scss";
import "../../../assets/themes/default/share.scss";

const BPMComponent: React.FC<Props> = (props: Props) => {
    const [ruleData, setRuleData] = React.useState<any>([]);
    const [selectedRule, setSelectedRule] = React.useState<any>({});
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [fileDetails, setFileDetails] = React.useState<any>();
    const [pageSize, setPageSize] = React.useState(10);
    const [currentPageNo, setCurrentPageNo] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(1);
    const [totalRecord, setTotalRecord] = React.useState(0);
    const [currentIndex, setCurrentIndex] = React.useState(null);
    const [triggerMode, setTriggerMode] = React.useState('ADD');
    const [etidList, setEtidList] = React.useState([]);
    const [fieldList, setFieldList] = React.useState([]);
    const [activeEncryptedHash, setActiveEncryptedHash] = React.useState<any>();
    const [activeDcryptedHash, setActiveDcryptedHash] = React.useState<any>();
    const [activeHashFH, setActiveHashFH] = React.useState<any>();
    const [showDecrypt, setShowDecrypt] = React.useState(false);
    const executionTypeList: any[] = [{ value: '1', name: 'trigger' }, { value: '2', name: 'scheduled' }];
    const languageList: any[] = [{ value: '1', name: 'Node' }];
    const statusList: any[] = [{ value: '1', name: 'WIP' }, { value: '2', name: 'Active' }, { value: '3', name: 'Not Active' }];
    const [mode, setMode] = React.useState('ADD');
    const [showBPMRuleSetting, setShowBPMRuleSetting] = React.useState(false);
    const [initialValue, setInitialValue] = React.useState<any>({
        SourceOrg: '',
        ExecutionType: '',
        Scheduled: '',
        Description: '',
        Language: '',
        Code: '',
        Share: 'true',
        StatusId: '',
        Triggers: [],
        Family: ''

    });



    useEffect(() => {
        if (pageSize && currentPageNo) {
            getRules({ pageNo: currentPageNo, pageSize: pageSize });
        }
    }, [pageSize, currentPageNo]);



    const getRules = (param: any) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_BPM_RULES, null, null, param, {
            ETId: 102,
            SV: 1
        }).then(res => {
            props.toggleLoader(false);
            setRuleData(res.data.data);
            setTotalRecord(res.data.total);
            if (parseInt(res.data.total) % pageSize === 0) {
                setTotalPage((parseInt(res.data.total) / pageSize));
            }
            else {
                setTotalPage((Math.floor(parseInt(res.data.total) / pageSize)) + 1);
            }
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    if (response.message != "Invalid ETId")
                        Toaster.errorToaster(response.message);
                });
            }
        });
    }

    const getEtidsList = () => {
        RestApiService.invoke(ApiEndPoints.GET_ETIDs, null, null, null, {
            ETId: 50,
            SV: 1
        }).then((res: any) => {
            res.data.data.unshift({ ETId: 50, TableName: "schemas" });
            let etidListValue = [];
            res.data.data.map((item: any, index: any) => {
                etidListValue.push({ value: String(item.ETId), label: `${item.TableName} (${item.ETId})` })
            });
            setEtidList(etidListValue);
        }).catch(error => {
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
        });
    }

    const getEtidFields = (etid) => {
        RestApiService.invoke(ApiEndPoints.GET_FIELDS, null, null, {
            ETId: parseInt(etid, 10),
            SV: 1
        }, null).then((res: any) => {
            setFieldList(res.data.fields)
        }).catch(error => {
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
        });
    }
    const saveBPMEnvelope = (values: any) => {
        const bpm = values;
        bpm.ExecutionType = parseInt(bpm.ExecutionType)
        bpm.Language = parseInt(bpm.Language)
        bpm.StatusId = parseInt(bpm.StatusId)
        bpm.Share = (bpm.Share === 'true')
        bpm.ExecutionType === 1 ? delete bpm.Scheduled : delete bpm.Triggers
        if (bpm.Triggers && bpm.Triggers.length > 0) {
            bpm.Triggers = bpm.Triggers.map((item, index) => {
                if (item.Fields && item.Fields.length === 0) {
                    delete item.Fields
                } else {
                    item.Fields = item.Fields.map((data, index2) => {
                        data.Condition = { [data.Condition.key]: data.Condition.value }
                        return data;
                    })
                }
                item.ETId = parseInt(item.ETId)
                return item;
            })
        }
        const bpmData = {
            ETId: 102,
            SV: 1,
            Data: [bpm]
        };
        const bpmData_100 = {
            ETId: 100,
            SV: 1,
            Data: [bpm]
        };
        RestApiService.invoke(ApiEndPoints.SAVE_BPM_RULE, null, bpmData_100, null, {
            ETId: 100,
            SV: 1
        }).then((res_100: AxiosResponse) => {
            RestApiService.invoke(ApiEndPoints.SAVE_BPM_RULE, null, bpmData, null, {
                ETId: 102,
                SV: 1
            }).then((res: any) => {
                props.toggleLoader(false);
                Toaster.successToaster('BPM', 'BPM Rule submitted successfully ');
                closeBPMRuleSetting();
                setTimeout(() => {
                    getRules({ pageNo: currentPageNo, pageSize: pageSize });
                }, 2000);
            }).catch(error => {
                props.toggleLoader(false);
                console.log('Get error', error.response);
                if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    error.response.data.errors.forEach((response) => {
                        Toaster.errorToaster(response.message);
                    });
                }
            });
        }).catch((error: any) => {
            console.log("error=>", error);
        });

    }


    const onFileChange = (file: any, setFieldValue) => {
        if (file.target && file.target.files && file.target.files.length > 0) {
            setFileDetails(file.target.files[0].name);
            let dataResult = new FormData();
            dataResult.append('file', file.target.files[0]);
            RestApiService.invoke(ApiEndPoints.STORE_FILE, null, dataResult, null, {
                ETId: 17,
                SV: 1,
            }).then(res => {
                Toaster.successToaster("onFileChange", "Upload File Successful");
                setFieldValue('Code', res.data.response.data.UID[0])
            }).catch(error => {
                console.log('Get error', error.response);
                if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    error.response.data.errors.forEach((response) => {
                        Toaster.errorToaster(response.message);
                    });
                }
            })
        }
    }

    const getFileHash = (UID) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.GET_FILE_HASH, null, { UID: UID }, null, {
            ETId: 17,
            SV: 1
        }).then((res: any) => {
            props.toggleLoader(false);
            openHashesModel(res.data.data.FH)
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
        });
    }

    const openHashesModel = (FH: any) => {
        setActiveHashFH(FH);
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.VIEW_FILEHASH, { FH: FH }, null, null, {
            ETId: 1000,
            SV: 1
        }).then((res: any) => {
            props.toggleLoader(false);
            if (res.data.success) {
                setActiveEncryptedHash(res.data.data);
                setOpen1(true)
            }
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
        });

    }

    const dcryptHash = (FileHash: any) => {
        props.toggleLoader(true);
        RestApiService.invoke(ApiEndPoints.DECRYPT_FILEHASH, { FH: FileHash }, null, null, {
            ETId: 1000,
            SV: 1
        }).then((res: any) => {
            props.toggleLoader(false);
            if (res.data.success) {
                setActiveDcryptedHash(res.data.data);
                setShowDecrypt(true);
            }
        }).catch(error => {
            props.toggleLoader(false);
            console.log('Get error', error.response);
            if (error.response && error.response.data && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
            }
        });
    }

    const handleAction = (action: any, item: any) => {
        if (action == 'view') {
            setOpen(true);
            setSelectedRule(item);
        }
    }

    const goToPreviousPage = useCallback((page: any) => {
        setCurrentPageNo(page);
    }, []);

    const goToNextPage = useCallback((page: any) => {
        setCurrentPageNo(page);
    }, []);

    const isLessthanTotalElement = useCallback(() => {
        if ((currentPageNo * pageSize) === (totalPage - 1))
            return true;
        else
            return false;
    }, [currentPageNo, pageSize, totalPage]);

    const changePageSize = useCallback((value: any) => {
        setPageSize(value);
        setCurrentPageNo(1);
    }, []);
    const openBPMRuleSetting = (value) => {
        if (value) {
            setShowBPMRuleSetting(true);
            setInitialValue({
                SourceOrg: '',
                ExecutionType: '',
                Scheduled: '',
                Description: '',
                Language: '',
                Code: '',
                Share: 'true',
                StatusId: '',
                Triggers: [],
                Family: ''
            })
            getEtidsList();
        }
    }

    const closeBPMRuleSetting = () => {
        setShowBPMRuleSetting(false);
        setInitialValue({
            SourceOrg: '',
            ExecutionType: '',
            Scheduled: '',
            Description: '',
            Language: '',
            Code: '',
            Share: 'true',
            StatusId: '',
            Triggers: [],
            Family: ''
        })

    }

    const handleClose = useCallback(() => {
        setOpen(false);

    }, []);

    const editTrigger = (item: any, index: any) => {
        setCurrentIndex(index);
        setTriggerMode('EDIT')

    };



    const _renderForm = ({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }: any) => {
        if (initialValue) return (
            <form className="schema-form" onSubmit={handleSubmit}>
                <div className={`schemaedit ${showBPMRuleSetting && (mode === 'ADD') ? "" : "hide"}`}>
                    <div className={`schemadetails ${values && values.ExecutionType === '1' ? "trigger-class" : ""}`}>
                        <div className="schema-heading">
                            <span className="mode-heading"> BPM Rule Settings </span>
                            <strong onClick={closeBPMRuleSetting} ><Close /></strong>
                        </div>
                        <div className="schematablecontent">
                            <div className="schematablecontenttype">
                                <div className="schemacontent">
                                    <strong>Source Org*</strong>
                                    <p>
                                        <input
                                            type="text"
                                            value={values.SourceOrg}
                                            name="SourceOrg"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.SourceOrg && touched.SourceOrg && <div className="alert alert-danger my-3">{errors.SourceOrg}</div>}
                                    </p>
                                </div>
                                <div className="schemacontent">
                                    <strong>Execution Type*</strong>
                                    <p>
                                        <select className="bpmddl"
                                            name="ExecutionType"
                                            onBlur={handleBlur}
                                            value={values.ExecutionType}
                                            onChange={handleChange}>
                                            <option value={''} hidden label="Select Execution Type"></option>
                                            {executionTypeList.length > 0 && executionTypeList.map((elem) =>
                                                <option key={elem.name} disabled={elem.value === '2'} value={elem.value}>{elem.name}</option>
                                            )
                                            }
                                        </select>
                                        {errors.ExecutionType && touched.ExecutionType && <div className="alert alert-danger my-3">{errors.ExecutionType}</div>}
                                    </p>

                                </div>
                                {values && values.ExecutionType === '2' && <div className="schemacontent">
                                    <strong>Scheduled*</strong>
                                    <p>
                                        <input
                                            type="text"
                                            value={values.Scheduled}
                                            name="Scheduled"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </p>
                                </div>}
                                <div className="schemacontent">
                                    <strong>Code*</strong>

                                    <p>
                                        <input
                                            disabled
                                            type="text"
                                            value={values.Code}
                                            name="Code"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.Code && touched.Code && <div className="alert alert-danger my-3">{errors.Code}</div>}
                                    </p>
                                </div>
                                <div className="schemacontent">
                                    <strong></strong>

                                    <form className="file-store"
                                        onSubmit={(e: any) => e.preventDefault()}
                                    >
                                        <div className="file-section">
                                            <span className="file-name">{fileDetails}</span>
                                            <input
                                                type="file"
                                                className={"hidefile"}
                                                onChange={(event) => {
                                                    onFileChange(event, setFieldValue)
                                                    //  setFieldValue('Code', fileDetails)
                                                }}
                                                id="icon-button-file"
                                            />
                                            <label htmlFor="icon-button-file">
                                                <Tooltip title="Choose File" placement="bottom" key={"choosefile1"}>
                                                    <Button
                                                        variant="contained"
                                                        component="span"
                                                        size="large"
                                                        className={"btn-primary"}
                                                    >
                                                        <span className="labelspan">Choose File</span>
                                                        <InsertDriveFile />
                                                    </Button>
                                                </Tooltip>
                                            </label>
                                        </div>
                                    </form>
                                </div>
                                <div className="schemacontent">
                                    <strong>Description*</strong>
                                    <p>
                                        <input
                                            type="text"
                                            value={values.Description}
                                            name="Description"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.Description && touched.Description && <div className="alert alert-danger my-3">{errors.Description}</div>}
                                    </p>
                                </div>
                            </div>
                            <div className="schematablecontenttype">
                                <div className="schemacontent">
                                    <strong>Language*</strong>
                                    <p>
                                        <select className="bpmddl"
                                            name="Language"
                                            onBlur={handleBlur}
                                            value={values.Language}
                                            onChange={handleChange}>
                                            <option value={''} hidden label="Select Language"></option>
                                            {languageList.length > 0 && languageList.map((elem) =>
                                                <option key={elem.name} value={elem.value}>{elem.name}</option>
                                            )
                                            }
                                        </select>
                                        {errors.Language && touched.Language && <div className="alert alert-danger my-3">{errors.Language}</div>}
                                    </p>
                                </div>

                                <div className="schemacontent">
                                    <strong>Share*</strong>
                                    <p><span> <input
                                        type="radio"
                                        name="Share"
                                        value={'true'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        checked={values.Share == 'true'}

                                    /> Yes
                                    </span>
                                        <span><input
                                            type="radio"
                                            name="Share"
                                            value={'false'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            checked={values.Share == 'false'}

                                        /> No
                                        </span>
                                        {errors.Share && touched.Share && <div className="alert alert-danger my-3">{errors.Share}</div>}
                                    </p>

                                </div>
                                <div className="schemacontent">
                                    <strong>Status*</strong>
                                    <p>
                                        <select className="bpmddl"
                                            name="StatusId"
                                            onBlur={handleBlur}
                                            value={values.StatusId}
                                            onChange={handleChange}>
                                            <option value={''} hidden label="Select Status"></option>
                                            {statusList.length > 0 && statusList.map((elem) =>
                                                <option key={elem.name} value={elem.value}>{elem.name}</option>
                                            )
                                            }
                                        </select>
                                        {errors.StatusId && touched.StatusId && <div className="alert alert-danger my-3">{errors.StatusId}</div>}
                                    </p>
                                </div>
                                <div className="schemacontent">
                                    <strong>Family*</strong>
                                    <p>
                                        <input
                                            type="text"
                                            value={values.Family}
                                            name="Family"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.Family && touched.Family && <div className="alert alert-danger my-3">{errors.Family}</div>}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {values && values.ExecutionType === '1' && <div className="schematablelist viewtables">
                            <strong className="trigger-label">Triggers*</strong>
                            <FieldArray name="Triggers">
                                {
                                    (fieldArray) => {
                                        return ((
                                            <div className="tab-content">
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        fieldArray.insert((values.Triggers && values.Triggers.length) || 0, {
                                                            ETId: '',
                                                            Fields: []
                                                        });
                                                        setCurrentIndex((values.Triggers && values.Triggers.length) || 0);
                                                        setTriggerMode('ADD');
                                                    }}>Add Triggers</button>
                                                <TableContainer>
                                                    <Table className="trigger-table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Actions</TableCell>
                                                                <TableCell>ETId</TableCell>
                                                                <TableCell>Field Name</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                initialValue.Triggers && initialValue.Triggers.map((item: any, index: number) =>
                                                                    <TableRow key={"btndeletekey_row" + index}>
                                                                        <TableCell key={"btndeletekey" + index}>
                                                                            <Delete titleAccess={"Delete"} onClick={() => confirmAlert({
                                                                                title: 'Delete Confirmation',
                                                                                message: 'Are you sure you want to delete this?',
                                                                                buttons: [
                                                                                    {
                                                                                        label: 'Yes',
                                                                                        onClick: () => {
                                                                                            fieldArray.remove(index);
                                                                                            const currentState = initialValue;
                                                                                            currentState.Triggers.splice(index, 1);
                                                                                            setInitialValue(currentState);
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        label: 'No',
                                                                                        onClick: () => { }
                                                                                    }
                                                                                ]
                                                                            })}
                                                                            />
                                                                            <Create onClick={() => editTrigger(item, index)} />
                                                                        </TableCell>
                                                                        <TableCell key={"etid" + index}>{item['ETId']}</TableCell>
                                                                        <TableCell key={"field" + index}>
                                                                            <TableRow>
                                                                                {item["Fields"] && item["Fields"].map((field: any, index2: number) =>
                                                                                    <TableCell key={index2} className={"cellboarder"}>
                                                                                        {field.FieldName}
                                                                                    </TableCell>

                                                                                )}
                                                                            </TableRow>
                                                                        </TableCell>
                                                                    </TableRow>)
                                                            }
                                                            {initialValue.Triggers.length == 0 && <TableRow>
                                                                <TableCell colSpan={5} align={"center"}>
                                                                    No Data Found
                                                                </TableCell>
                                                            </TableRow>

                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                                {
                                                    values.Triggers && values.Triggers[currentIndex] && (
                                                        <div className={`schema-detail-field`}>
                                                            <div className="heading">
                                                                <h4> Triggers</h4>
                                                            </div>
                                                            <div className="schema-edit-field">
                                                                <div className="schema-edit">
                                                                    <div className="trigger-section">
                                                                        <strong>ETId*</strong>
                                                                        <span>
                                                                            <select
                                                                                className="form-control"
                                                                                name={`Triggers.${currentIndex}.ETId`}
                                                                                value={values.Triggers[currentIndex].ETId}
                                                                                onBlur={handleBlur}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    getEtidFields(event.target.value);
                                                                                }}
                                                                            >
                                                                                <option value={''} hidden label="Select ETId"></option>
                                                                                {
                                                                                    etidList && etidList.length > 0 && etidList.map((item: any) => (
                                                                                        <option key={item.value} value={item.value} label={item.value}></option>
                                                                                    ))
                                                                                }
                                                                            </select>

                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {values.Triggers[currentIndex].ETId !== '' && <div className="schema-edit-field">

                                                                <FieldArray name={`Triggers.${currentIndex}.Fields`}>
                                                                    {
                                                                        (fieldProps: any) => (
                                                                            <div className="schema-edit">

                                                                                <div className="trigger-section">
                                                                                    <strong>Fields</strong>
                                                                                    <span>
                                                                                        <select className="form-control" onChange={(event: any) => {
                                                                                            if (!(values.Triggers[currentIndex].Fields.find((field: any) => field.FieldName === event.target.value))) {
                                                                                                fieldProps.insert(0, {
                                                                                                    FieldName: event.target.value,
                                                                                                    Condition: {
                                                                                                        key: '',
                                                                                                        value: ''
                                                                                                    }
                                                                                                });
                                                                                            }
                                                                                        }}>
                                                                                            <option hidden value={''} label={'Select Fields'} />
                                                                                            {
                                                                                                fieldList && fieldList.length > 0 && fieldList.map((item: any, index: number) => item && item.FieldName &&
                                                                                                    <option key={index} value={fieldList[index].FieldName} label={fieldList[index].FieldName} />)
                                                                                            }
                                                                                        </select>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="schema-edits">
                                                                                    {
                                                                                        values.Triggers && values.Triggers[currentIndex] && values.Triggers[currentIndex].Fields && values.Triggers[currentIndex].Fields.map((field: any, fieldIndex: number) => {
                                                                                            return (
                                                                                                <div className="exitinf-fields" key={fieldIndex} >
                                                                                                    <span>
                                                                                                        <input
                                                                                                            disabled
                                                                                                            type="text"
                                                                                                            className={`form-control ${!(fieldList && fieldList.find((field: any) => (field.FieldName === values.Triggers[currentIndex].Fields[fieldIndex]))) ? 'has-error' : ''}`}
                                                                                                            placeholder=""
                                                                                                            value={values.Triggers[currentIndex].Fields[fieldIndex].FieldName}
                                                                                                            name={`Triggers.${currentIndex}.Fields.${fieldIndex}.FieldName`}
                                                                                                            onChange={handleChange} />
                                                                                                    </span>
                                                                                                    <div className="add-schema-list" >
                                                                                                        <div className="schema-choices">
                                                                                                            <div className="schema-values">
                                                                                                                <strong>Condition</strong>
                                                                                                                <span> <input
                                                                                                                    type="text"
                                                                                                                    className="form-control"
                                                                                                                    placeholder="key"
                                                                                                                    value={values.Triggers[currentIndex].Fields[fieldIndex].Condition.key}
                                                                                                                    name={`Triggers.${currentIndex}.Fields.${fieldIndex}.Condition.key`}
                                                                                                                    onChange={handleChange} /> </span>
                                                                                                            </div> :
                                                                                                            <div className="schema-values">
                                                                                                                <span>
                                                                                                                    <input
                                                                                                                        type="number"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="value"
                                                                                                                        value={values.Triggers[currentIndex].Fields[fieldIndex].Condition.value}
                                                                                                                        name={`Triggers.${currentIndex}.Fields.${fieldIndex}.Condition.value`}
                                                                                                                        onChange={handleChange} />
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            {
                                                                                                                (!(initialValue && initialValue.Triggers[currentIndex] && initialValue.Triggers[currentIndex].Fields && initialValue.Triggers[currentIndex].Fields[fieldIndex])) &&
                                                                                                                <button type="button" className="btn-primary" onClick={() => fieldProps.remove(fieldIndex)}>x</button>
                                                                                                            }
                                                                                                        </div>

                                                                                                    </div>

                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        )}
                                                                </FieldArray>
                                                            </div>}
                                                            <div className="schemabtn">
                                                                {triggerMode === 'ADD' && <button type="button" className="btn btn-primary" onClick={() => {
                                                                    fieldArray.remove(currentIndex);
                                                                    setCurrentIndex(null);
                                                                }}>Cancel</button>}
                                                                {triggerMode !== 'ADD' && <button type="button" className="btn btn-primary" onClick={() => {
                                                                    setFieldValue(`Triggers.${currentIndex}`, initialValue.Triggers[currentIndex]);
                                                                    setCurrentIndex(null);
                                                                }} >Cancel</button>}
                                                                <button
                                                                    disabled={(values.Triggers[currentIndex].ETId === "")}
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => {
                                                                        setInitialValue({
                                                                            ...initialValue,
                                                                            SourceOrg: values.SourceOrg,
                                                                            ExecutionType: values.ExecutionType,
                                                                            Scheduled: values.Scheduled,
                                                                            Description: values.Description,
                                                                            Language: values.Language,
                                                                            Code: values.Code,
                                                                            Share: values.Share,
                                                                            StatusId: values.StatusId,
                                                                            Triggers: values.Triggers,
                                                                            Family: values.Family
                                                                        });
                                                                        setCurrentIndex(null);
                                                                        Toaster.successToaster("setIndex", "Please click on Submit button to save the data.")
                                                                    }} >Apply</button>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        ));
                                    }
                                }
                            </FieldArray>
                        </div>}
                        <div className="schemabtn" id="submit">
                            <button className={`btn btn-primary submit`} disabled={(values && values.ExecutionType === '1') && initialValue.Triggers.length == 0} type="submit" onSubmit={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </form>

        )
    }


    const tableColumnList = useMemo(() => [
        { name: "Action", db: "Action" },
        { name: "Execution Type", db: "ExecutionType" },
        { name: "Code", db: "CodeFile" },
        { name: "Share", db: "Share" },
        { name: "Status Id", db: "StatusId" },
        { name: "Description", db: "Description" },
        { name: "Scheduled", db: "Scheduled" },
        { name: "Language", db: "Language" }
    ], []);
    const ViewIconCellRenderer = useCallback((value: any): React.ReactNode => {
        return (
            <div className='role-icon'>
                <Tooltip title="View" placement="right">
                   <span><Visibility color="primary" onClick={() => handleAction('view', value?.data)} /></span>
                </Tooltip>
            </div>
        )
    }, []);

    const customActionColumnFormatters = [
        ViewIconCellRenderer
    ];


    return (
        <div className="Innerheading">
            <div className='view-wrapper view-wrapper-dashboard-details'>
                <NewSubHeaderComponent
                    headingText={'Business Process Management Rules'}
                    showBtn={true}
                    btnText={'Add Rule'}
                    onButtonClick={(value: any) => openBPMRuleSetting(value)}
                />
                <div className="Innerdetails dx-card details-card dx-theme-background-color">
                    <div className="grid">
                        <DynamicTableList
                            dataSource={ruleData}
                            columnList={tableColumnList}
                            fromComponent={'BPMComponent'}
                            showPagination
                            goToPreviousPage={goToPreviousPage}
                            goToNextPage={goToNextPage}
                            changePageSize={changePageSize}
                            pageSize={pageSize}
                            currentPageNo={currentPageNo}
                            totalPage={totalPage}
                            totalRecord={totalRecord}
                            isLessthanTotalElement={isLessthanTotalElement()}
                            showCustomActions
                            customActionColumnFormatters={customActionColumnFormatters}
                        />
                    </div>
                    <div className="bpm-main">
                        <div className={`schemaedits ${showBPMRuleSetting && (mode === 'ADD') ? "" : "hide"}`}>
                            <Formik
                                initialValues={initialValue}
                                render={_renderForm}
                                enableReinitialize
                                validationSchema={
                                    yup.object().shape({
                                        SourceOrg: yup.string().required('SourceOrg Field is Required'),
                                        ExecutionType: yup.string().required('ExecutionType Field is Required'),
                                        Description: yup.string().required('Description Field is Required'),
                                        Language: yup.string().required('Language Field is Required'),
                                        Code: yup.string().required('Code Field is Required'),
                                        Share: yup.string().required('Share Field is Required'),
                                        StatusId: yup.string().required('StatusId Field is Required'),
                                        Family: yup.string().required('Family Field is Required')
                                    })}
                                onSubmit={values => saveBPMEnvelope(values)}>
                            </Formik>
                        </div>
                    </div>
                </div>

                
                {selectedRule && <ModalBox
                    visible={open}
                    onHiding={handleClose}
                    showCloseButton
                    showTitle
                    title='Summary'
                    height={600}
                    width={800}
                    dragEnabled
                    hideOnOutsideClick
                >
                    <ScrollView
                        width='100%'
                        height='100%'
                        direction='both'
                        showScrollbar='always'
                        scrollByThumb
                        scrollByContent
                    >
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><strong>Source Org</strong></TableCell>
                                        <TableCell>{selectedRule.SourceOrg}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Execution Type</strong></TableCell>
                                        <TableCell>{selectedRule.ExecutionType}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Status Id</strong></TableCell>
                                        <TableCell>{selectedRule.StatusId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>UID</strong></TableCell>
                                        <TableCell>{selectedRule.UID}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>EID</strong></TableCell>
                                        <TableCell>{selectedRule.EId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>ORG Id</strong></TableCell>
                                        <TableCell>{selectedRule.ORGId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Created At</strong></TableCell>
                                        <TableCell>{moment.utc(selectedRule.CreatedAt).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Updated At</strong></TableCell>
                                        <TableCell>{moment.utc(selectedRule.UpdatedAt).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Status</strong></TableCell>
                                        <TableCell>{selectedRule.Status}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Language</strong></TableCell>
                                        <TableCell>{selectedRule.Language}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Code</strong></TableCell>
                                        <TableCell>{selectedRule.Code} <IconButton onClick={() => getFileHash(selectedRule.Code)}><Visibility /></IconButton></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>IsDeleted</strong></TableCell>
                                        <TableCell>{selectedRule.IsDeleted === true ? 'true' : 'false'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Approved</strong></TableCell>
                                        <TableCell>{selectedRule.Approved ? selectedRule.Approved : 'null'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Last Run</strong></TableCell>
                                        <TableCell>{selectedRule.LastRun ? selectedRule.LastRun : 'null'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Share</strong></TableCell>
                                        <TableCell>{selectedRule.Share === true ? 'true' : 'false'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Last Modified By</strong></TableCell>
                                        <TableCell>{selectedRule.LastModifiedBy}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ScrollView>
                </ModalBox>}

                <Dialog onClose={() => { setOpen1(false) }} aria-labelledby="customized-dialog-title" open={open1} className="modalpopoup">
                    <DialogTitle disableTypography>
                        <h3>{activeHashFH}</h3>
                        <IconButton aria-label="close" onClick={() => { setOpen1(false); setShowDecrypt(false); }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <strong>Encrypted FileHash</strong>
                            <div className="activeEncryptedHash">{activeEncryptedHash}</div>
                        </Typography>
                        {showDecrypt && <Typography gutterBottom>
                            <strong>Decrypted DataHash</strong>
                            <div className="decrypted-text">
                                {JSON.stringify(activeDcryptedHash, undefined, 4)}
                            </div>
                        </Typography>}
                    </DialogContent>
                    {!showDecrypt && <DialogActions>
                        <button type="button" className="btn btn-primary" onClick={() => dcryptHash(activeHashFH)} color="primary">
                            Decrypt
                        </button>
                    </DialogActions>}
                </Dialog>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BPMComponent);


interface Props {
    toggleLoader: any;
}