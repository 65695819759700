import React from "react";
import Card from "../../../Cards/Card";
import './ProductRelease.scss'
import { TickerCard } from "../../../ticker-card/TickerCard";
import { DataGrid } from "devextreme-react";
import { Column, ColumnChooser, ColumnFixing, Paging, Sorting } from "devextreme-react/data-grid";
import { CardTask } from "../../../card-Task/CardTask";
const releaseUpcoming = [
    { id: 1, "launch": "Platform Version 2.5.1 ", "date": "26 Jun 2024", "status": "Current" },
    { id: 2, "launch": "FiVe Version 2.1.0.0", "date": "26 Jun 2024", "status": "Current" },
    { id: 3, "launch": "Platform Version 2.5.0", "date": "21 Mar 2024", "status": "Previous" },
    { id: 4, "launch": "FiVe Version 2.0.1.1", "date": "16 Nov 2022", "status": "Previous" },
]


interface IReleaseProps {

}


function ProductRelease({ ...props }: IReleaseProps) {
    return (
        <div className="release-card">

            <div className="title">Product Release Schedule</div>
            <div className="overflow-custom-table">
                <table className="custom-table">
                    <thead>
                        <tr>
                            <th><span>Launch</span></th>
                            <th><span>Date</span></th>
                            <th><span>Product Status</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {releaseUpcoming.map((item, index) => (
                            <tr key={index}>
                                <td><span className="version-launch">{item.launch}</span></td>
                                <td>{item.date}</td>
                                <td>{item.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProductRelease;