import React, { useEffect, Fragment } from 'react';
import "./MultipleSelectDropDown.scss";
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme, WithStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { any } from 'prop-types';
import { PROFILE_GET_PROFILE } from '../../../../redux/types/profileTypes';
import { MenuList, Paper } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 20;

const useStyles = makeStyles({
  dropdownUserStyle:
  {
    top: '51% !important',
    position: 'absolute',

  },
  dropdownRoleStyle:
  {
    top: '54% !important',
    position: 'absolute',

  },
});


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,

    },
  },
};

function getStyles(name: any, personName: any[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultipleSelectComponent = (props: MultipleSelectProps) => {
  const theme = useTheme();
  const [name, setName] = React.useState<string[]>([]);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    setName(event.target.value as string[])

  };

  useEffect(() => {
    if (name && props && props.from === 'ROLE') {
      props.handleScopes(name);
    }
    else if (name && props && props.from !== 'ROLE' && props.from !=='RoleList') {
      props.handleRoles(name.map((item: any, index) => props.data.find(currentData => currentData.roleName === item))
      )

    }
    else if (props && props.from === 'RoleList') {
      props.handleRoleList(name.map((item: any, index) => props.data.find(currentData => currentData.userName === item))
      )

    }
  }, [name]);


  useEffect(() => {
    if (props && props.from === 'ROLE' && props.mode !== 'add') {
      setName(props.selectedValues);
    }
    else if (props && props.from === 'USER' && props.mode !== 'add') {
      setName(props.selectedValues.map((currentEntity: any) => currentEntity.roleName));
    }
  }, []);


  return (
    <div className="Role-multi-select">
      <FormControl disabled={props.mode === 'view'}>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={name}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <span className="Role-multi-chip">
              {props.from === 'ROLE' && (selected as string[]).map((value, index) => (
                <Fragment key={index}>
                  {props && props.mode === 'view' && <Chip label={value} />}
                  {props && props.mode !== 'view' && <Chip key={value} label={value} onDelete={handleChange} color="primary" />}
                </Fragment>
              ))}
              {props.from !== 'ROLE' && (selected as string[]).map((value: any, index) => (
                <Fragment key={index}>
                  <Chip key={value.id} label={value} onDelete={handleChange} color="primary" />
                </Fragment>
              ))}
            </span>
          )}
          MenuProps={{ classes: { paper: props.from === 'ROLE' ? classes.dropdownRoleStyle : classes.dropdownUserStyle } }}

        >
          {
            props.from === 'ROLE' &&
            props.data.map((item, index) =>
              <MenuItem className="roleOptions" key={index} value={item} style={getStyles(item, name, theme)}>
                <Checkbox className="rolecheck" checked={name.indexOf(item) > -1} color="primary" />
                <ListItemText className="rolechecktext" primary={item} />
              </MenuItem>
            )
          }
          {
            props.from !== 'ROLE' &&  props.from !== 'RoleList' &&
            props.data.map(d => d.roleName).map((item, index) =>
              <MenuItem className="roleOptions" key={index} value={item} style={getStyles(item, name, theme)}>
                <Checkbox className="rolecheck" checked={name.indexOf(item) > -1} color="primary" />
                <ListItemText className="rolechecktext" primary={item} />
              </MenuItem>
            )
          }
          {
            props.from === 'RoleList' &&
            props.data.map(d => d.userName).map((item, index) =>
              <MenuItem className="roleOptions" key={index} value={item} style={getStyles(item, name, theme)}>
                <Checkbox className="rolecheck" checked={name.indexOf(item) > -1} color="primary" />
                <ListItemText className="rolechecktext" primary={item} />
              </MenuItem>
            )
          }

        </Select>
      </FormControl>
    </div>
  );
}

export default MultipleSelectComponent;

interface MultipleSelectProps {
  data: any;
  labelname: any;
  mode?: string;
  selectedValues?: any;
  handleScopes?: any;
  handleRoles?: any;
  handleRoleList?: any;
  from: string;
}
