import React from 'react';
import { Admin, Building, Bullet, Hierarchical, Home, Logouts, Shares, Speedometers, Unlocked } from '../../assets/svg';

const SidebarMenu = [
    {
        elementAttr: { id: 'sideBarHomeIcon' },
        name: 'Home',
        icon: <Home />,
        url: '/landing'
    },
    {
        elementAttr: { id: 'sideDashboardIcon' },
        name: 'Dashboard',
        icon: <Speedometers />,
        url: '/page/dashboard'
    },
    {
        elementAttr: { id: 'sideSchemaIcon' },
        name: 'Schema',
        icon: <Hierarchical />,
        url: '/page/schema-list'
    },
    {
        elementAttr: { id: 'sideBPMIcon' },
        name: 'BPM',
        icon: <Building />,
        url: '/page/bpm'
    },
    {
        elementAttr: { id: 'sideListIcon' },
        name: 'List',
        icon: <Bullet />,
        url: '/page/list'
    },
    {
        elementAttr: { id: 'sideShareIcon' },
        name: 'Sharing',
        url: '/page/networking',
        icon: <Shares />,
        tooltip: 'Sharing',
        placement: 'right'
    },
    {
        elementAttr: { id: 'sideAdminIcon' },
        name: 'Admin',
        icon: <Admin />,
        url: '/page/admin/users'
    },
    {
        elementAttr: { id: 'sideLogoutIcon' },
        name: 'Logout',
         icon: <Unlocked />,
        // icon: <Logouts />,
        url: '/landing'
    }
];

export default SidebarMenu;