import React from 'react';
import { Friend, Keys, Setting, SettingCluster } from '../../assets/svg';

const SidebarAdminMenu = [
    {
        elementAttr: { id: 'sideUserIcon' },
        name: 'Users',
        url: '/page/admin/users',
        icon: <Friend />,
        tooltip: 'Users',
        placement: 'right'

    },
    {
        elementAttr: { id: 'sideRoleIcon' },
        name: 'Roles',
        icon: <Keys />,
        url: '/page/admin/roles',
        tooltip: 'Roles',
        placement: 'right'

    },
    // {
    //     elementAttr: { id: 'sideConfigureIcon' },
    //     name: 'Configuration Settings',
    //     icon: <Setting />,
    //     url: '/page/admin/settings',
    //     tooltip: 'Configuration Settings',
    //     placement: 'right'

    // }, 
    {
        elementAttr: { id: 'sideClusterIcon' },
        name: 'Cluster Management',
        icon: <SettingCluster />,
        url: '/page/admin/clusters',
        tooltip: 'Cluster manager',
        placement: 'right'

    }

];

export default SidebarAdminMenu;