import React, { useCallback, useRef, useState, useMemo, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


import { toggleLoader } from "../../redux/actions/loader.action";
import FileDownloadService from "../../services/file-download-services";
import Toaster from '../../services/toaster.services';
import { addOrganization, getDefaultOrganizations, getOrganization } from "../../api/organization/organization";

//commonComponent
import Tab from "../../shared/components/commonui/Tabs";
import SubHeader from "../../shared/components/commonui/SubHeader/SubHeader";

//Internal data sharing components
import ContractTable from "./Tables/contracts/ContractTable";
import DataSharingTable from "./Tables/sharing/DataSharingTable";
import OrganizationTable from "./Tables/organizations/OrganizationTable";

//css file
import './index.scss'
import { addConracts, getContracts } from "../../api/dataSharing/Contracts";
import { useScreenSize } from "../../utils/media-query";

function SharingComponent(props: ContractProps) {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [isVisiblePopup, setisVisisblepopup] = useState<boolean>(false);
    const [orgVisible, setOrgVisible] = useState<boolean>(false);
    const [importedOrgDetails, setImportedOrgDetails] = useState<Array<any>>([]);
    const [currentOrgDetails, setCurrentOrgDetails] = useState<any>({});
    const [currentOrgSelected, setCurrentOrgSelected] = useState<any>();
    const [shrVisible, setShrVisible] = useState<boolean>(false);
    const [selectedContract, setSelectedContract] = useState<any>({});
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);
    const [searchValueOrg, setSearchValueOrg] = useState<string>('');
    const [searchContract, setSearchContract] = useState<string>('');
    const [searchTextContract, setSearchTextContract] = useState<any>([]);
    const [searchShare, setSearchShare] = useState<string>('');
    const inputFileRef = useRef<HTMLInputElement | null>();
    const inputFileRefOrganization = useRef<HTMLInputElement | null>();
    const fileDownloadService = new FileDownloadService();
    const tabs = useMemo(() => [{ tagId: 1, name: "Organization" },
    { tagId: 2, name: "Contracts" }, { tagId: 3, name: "Shared Data" }], []);
    const [tabsWidth, setTabsWidth] = useState<number | string>('auto');
    const { isXSmall } = useScreenSize();

    const initializeComponent = () => {
        props.toggleLoader(true);
        Promise.all([getCurrentOrganizationDetails()]).then((responseList: any) => {
            if (responseList.length > 0) {
                setCurrentOrgDetails(responseList[0].data.data[0]);
            }
            props.toggleLoader(false);
        }).catch((errorList: any) => {
            props.toggleLoader(false);
            console.log(errorList, "values")
        })
    };

    // Create Contract and ORG
    const createOrganizationAndContract = async (dataObject: any, contractCase?: string) => {
        props.toggleLoader(true);
        const contractDataObject = { "SenderOrgId": dataObject["SenderOrgId"], "ReceiverOrgId": dataObject["ReceiverOrgId"], "Seed": dataObject["Seed"] };
        let ORGName = dataObject["ReceiverOrgName"];
        let ORGId = dataObject["ReceiverOrgId"];

        if (contractCase === "import") {
            ORGName = dataObject["SenderOrgName"];
            ORGId = dataObject["SenderOrgId"];
            contractDataObject["UID"] = dataObject["UID"];
        }

        const contractData = [contractDataObject];

        const orgData = [{
            "ORGName": ORGName,
            "ORGId": ORGId
        }];
        const orgInfoBody = { "ORGId": ORGId };

        try {
            const organizationInfoResponse = await getOrganization(orgInfoBody);
            if (organizationInfoResponse?.data?.data?.length > 0) {
                const addContractResult = await addConracts(contractData);
                if (addContractResult.status = 200) {
                    Toaster.successToaster("createOrganizationAndContract", "Contact Crated");
                    setisVisisblepopup(false);
                    setForceUpdate((prev) => !prev);
                }
            }
            else {
                Toaster.errorToaster("Please add organization first");
            }

            props.toggleLoader(false);
           


            //todo Throw new error if ES<20 (create a common error handler for that); 
        }
        catch (error: any) {
            props.toggleLoader(false);
            setisVisisblepopup(false);
            if (error?.response?.data?.errors?.length > 0) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                    Toaster.errorToaster(error.response.data.errors[i]?.message);
                }
            }
        }
    }

    useEffect(() => { initializeComponent() }, []);
    // handle import contract
    const contractImporterHandler = (orgDetails: any) => {
        createOrganizationAndContract(orgDetails, "import");
    }

    const itemRender = useCallback((params: any) => {
        return (
            <span>
                {params.name}
            </span>
        )
    }, []);

    useEffect(() => {
        setTabsWidth(isXSmall ? '100%' : 'auto');
      }, []);

    // Tab Data Change
    const handleTab = useCallback((event: any) => {
        setActiveTab(event.itemIndex)
    }, [])

    const handlePopup = useCallback(() => {
        setisVisisblepopup((prevValue) => !prevValue);
    }, []);


    const onImportButtonClick = useCallback(() => {
        inputFileRef.current.click();
    }, [inputFileRef]);

    const onExportButtonClick = useCallback((values) => {
        const orgDataJson = {
            ORGName: currentOrgDetails.ORGName,
            ORGId: currentOrgDetails.ORGId,
            ChainAddress: currentOrgDetails.ChainAddress
        }
        const fileName = `${currentOrgDetails.ORGName} (${currentOrgDetails.ORGId}).org` || 'organization';
        fileDownloadService.objectToJsonFile(orgDataJson, fileName);
    }, [currentOrgDetails]);

    const getCurrentOrganizationDetails = useCallback(() => {
        return getDefaultOrganizations({ "IsDefaultOrg": true })
    }, []);

    const fileUploadHandler = useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        fileDownloadService.getFileAndParseToJson(file).then((result: any) => {
            const orgDetails = JSON.parse(result);
            if ((orgDetails["ReceiverOrgId"] === currentOrgDetails.ORGId)) {
                contractImporterHandler(orgDetails);
            }
            else {
                Toaster.errorToaster("Invalid Contract")
            }
        }).catch((error) => {
            console.log("fileUploadHandler", error);
        });
        if (inputFileRef.current?.value) {
            inputFileRef.current.value = '';
        }

    }, [currentOrgDetails]);

    const organizationImportHandler = useCallback((orgDetails: any) => {
        // Popup Open
        setImportedOrgDetails(orgDetails);
        setOrgVisible(true)
    }, []);

    const createOrganization = async (dataObject: any,) => {
        props.toggleLoader(true);
        const orgData = [{
            "ChainAddress": dataObject["ChainAddress"],
            "ORGName": dataObject["ORGName"],
            "ORGId": dataObject['ORGId']
        }];
        const orgInfoBody = { "ORGId": dataObject['ORGId'] };

        try {
            const organizationInfoResponse = await getOrganization(orgInfoBody);
            if (organizationInfoResponse?.data?.data?.length > 0) {
                Toaster.infoToaster("Org Already Exists");
            }
            else {
                const addOrganizationResult = await addOrganization(orgData);

                if (addOrganizationResult.status == 200) {
                    if (addOrganizationResult?.data?.data?.ES > 20) {
                        Toaster.successToaster("createOrganizationAndContract", "Added organization");
                        setForceUpdate((prev) => !prev)
                    }
                    else {
                        Toaster.errorToaster("Failed Adding Organization")
                    }
                }
            }
            setOrgVisible(false);
            props.toggleLoader(false);
        }
        catch (error: any) {
            props.toggleLoader(false);
            if (error?.response?.data?.errors?.length > 0) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                    Toaster.errorToaster(error.response.data.errors[i]?.message);
                    break;
                }
            }
        }
    };


    const onImportButtonClickOrganisation = useCallback(() => {
        inputFileRefOrganization.current.click();
    }, []);

    const fileUploadHandlerOrganization = useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        fileDownloadService.getFileAndParseToJson(file).then((result: any) => {
            const orgDetails = JSON.parse(result);
            if ((orgDetails["ORGId"] && orgDetails["ORGName"] && orgDetails["ChainAddress"]) && (orgDetails["ORGId"] !== currentOrgDetails.ORGId)) {
                organizationImportHandler(orgDetails);
            }
            else {
                Toaster.errorToaster("Invalid Org file")
            }
        }).catch((error) => {
            console.log("fileUploadHandler", error);
        });
        if (inputFileRefOrganization.current?.value) {
            inputFileRefOrganization.current.value = '';
        }
    }, [inputFileRefOrganization]);


    const handleOrganizationDetails = useCallback((orgDetails: any) => {
        createOrganization(orgDetails);
    }, [createOrganization])

    const orgActionHandler = useCallback((orgDetail: any) => {
        setCurrentOrgSelected(orgDetail)
    }, []);

    const shrActionHandler = useCallback(() => {
        setShrVisible(false)
    }, [])

    const handleContractSelection = (contractDetails: any) => {
        setSelectedContract(contractDetails);
        setActiveTab(2);
    }

    // Search Organization
    const onSearchOrganization = (orgValue: any) => {
       
    }




    //  Search Contract
    const onSearchContract = (contVal: any) => {
        setSearchContract(contVal);
    }



    const searchContractValue = (contractValue: any) => {
        props.toggleLoader(true);
        getContracts(contractValue).then(res => {
            props.toggleLoader(false);
        }).catch(err => {
            props.toggleLoader(false);
            console.log(err, "Error Search")
        })
    }

    useEffect(() => {
        searchContractValue({ IsDefaultOrg: false })
    }, [searchContract])


    // Search Share
    const onSearchShare = (shareValue: any) => {
    }

    return (
        <div className="view data-share-list">
            <div className='view-wrapper view-wrapper-share-list list-page'>
                {/* Sub Header Component */}
                {/* Todo Create a component for uploading file which is hidden*/}
                <input type="file" id="file" ref={inputFileRef} style={{ display: 'none' }} onChange={fileUploadHandler} accept="application/JSON" />
                <input type="file" id="fileImportOrganization" ref={inputFileRefOrganization} style={{ display: 'none' }} onChange={fileUploadHandlerOrganization} accept="application/JSON" />
                {
                    activeTab === 0 ? <SubHeader
                        title="Data Sharing"
                        value={searchValueOrg}
                        onInput={(e: any) => onSearchOrganization(e.event.target.value)}
                        itemRendererOption={{
                            searchBoxProps: true,
                            btn1Props: {
                                showBtn: true,
                                btnProps: {
                                    text: 'Export',
                                    onClick: onExportButtonClick,
                                },
                            },
                            btn2Props: {
                                showBtn: true,
                                btnProps: {
                                    text: 'Import',
                                    onClick: onImportButtonClickOrganisation
                                }
                            }
                        }}
                    /> : null
                }

                {
                    activeTab === 1 ? <SubHeader
                        title="Data Sharing"
                        value={searchContract}
                        onInput={(e: any) => onSearchContract(e.event.target.value)}
                        itemRendererOption={{
                            searchBoxProps: true,
                            btn1Props: {
                                showBtn: true,
                                btnProps: {
                                    text: 'Create',
                                    onClick: handlePopup,
                                },
                            },
                            btn2Props: {
                                showBtn: true,
                                btnProps: {
                                    text: 'Import',
                                    onClick: onImportButtonClick
                                }
                            }
                        }}
                    /> : null
                }

                {
                    activeTab === 2 ? <SubHeader
                        title="Data Sharing"
                        value={searchShare}
                        onInput={(e: any) => onSearchShare(e.event.target.value)}
                        itemRendererOption={{
                            searchBoxProps: true,
                        }} /> : null
                }
                <div className='dx-card details-card'>
                    <Tab
                        width={tabsWidth}
                        scrollByContent
                        showNavButtons={false}
                        dataSource={tabs}
                        selectedIndex={activeTab}
                        className="dataSharing-tabs"
                        itemRender={itemRender}
                        onItemClick={(event: any) => {
                            handleTab(event)
                        }}
                    />

                    <div className="InnerDetails">
                        <div className="InnerDetailsTable">
                            {
                                activeTab === 0 && (
                                    <OrganizationTable
                                        orgVisible={orgVisible}
                                        setOrgVisible={setOrgVisible}
                                        importedOrgDetails={importedOrgDetails}
                                        handleOrganizationDetails={handleOrganizationDetails}
                                        setActiveTab={setActiveTab}
                                        setisVisisblepopup={setisVisisblepopup}
                                        actionButtonHanlder={orgActionHandler}
                                        forceUpdate={forceUpdate}
                                    />
                                )
                            }
                            {
                                activeTab === 1 && (
                                    <div>
                                        <ContractTable
                                            activeTab={activeTab}
                                            isVisiblePopup={isVisiblePopup}
                                            setisVisisblepopup={setisVisisblepopup}
                                            initialSelectedOrg={currentOrgSelected}
                                            handleContractSelection={handleContractSelection}
                                            createOrganizationAndContract={createOrganizationAndContract}
                                            searchTextContract={searchTextContract}
                                            setSearchTextContract={setSearchTextContract}
                                            forceUpdate={forceUpdate}
                                        />
                                    </div>
                                )
                            }

                            {
                                activeTab === 2 && (
                                    <div>
                                        <DataSharingTable
                                            contractDetails={selectedContract}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SharingComponent);

interface ContractProps {
    toggleLoader: any;
}
