import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import * as yup from 'yup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';

//common components
import Sharing from '../../../../shared/components/commonui/Sharing';
import ModalBox from '../../../../shared/components/commonui/Modal/ModalBox';
import Button from '../../../../shared/components/commonui/Button';

//services 
import { toggleLoader } from '../../../../redux/actions/loader.action';
import FileDownloadService from '../../../../services/file-download-services';
import {  generateSeed, getContracts, getUserGeneratedETIds } from '../../../../api/dataSharing/Contracts';
import {  getDefaultOrganizations } from '../../../../api/organization/organization';

//contants
import appConstants from '../../../../core/constants/appConstants';

//Types
import SelectWithLabel from '../../../../shared/components/commonui/HigherOrderComponents/SelectWithLabel';
import InputWithLabel from '../../../../shared/components/commonui/HigherOrderComponents/InputWithLabel';
import LabelText from '../../../../shared/components/commonui/HigherOrderComponents/LabelText';

// Scss
import './contractTable.scss';
import Toaster from '../../../../services/toaster.services';

const ContractTable = (props: IContractTableProps) => {
    const [contractList, setContractList] = useState<any>([]);
    const [viewCredentialApp, setViewCredntialApp] = useState<boolean>(false);
    const [allORGList, setAllORGLIST] = useState<any>([]);
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);
    const [currentOrgDetails, setCurrentOrgDetails] = useState<any>({});
    const fileDownloadService = new FileDownloadService();
    const [orderIndexedFields, setOrderIndexedFields] = useState([{ 'orderBy': 'signedAt', 'order': 'desc', 'isActive': false }]);
    const [order, setOrder] = useState<number>(-1);
    const [orderBy, setOrderBy] = useState<string>("CreatedAt");
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [nextPage, setNextPage] = useState<any>();
    const [prePage, setPrePage] = useState<any>();
    const [userGeneratedETIds, setUserGeneratedETIds] = useState<string[]>([]);
    const [sharingInitialVal, setSharingInitialVal] = useState<any>({ ORGName: '', UID: '', ETId: '', StartDate: null, EndDate: null });
    const validationDataSharing = yup.object().shape({
        ORGName: yup.string().required('Organization Name is required'),
        UID: yup.string().required('Contract Id is required'),
        ETId: yup.string().required('ETId is required')
    });

    const tabColumnList = useMemo(() => [
        { name: "Contract Id", db: "UID" },
        { name: "Organization Id", db: "ORGId" },
        { name: "Organization Name", db: "ORGName" },
        { name: "Organization Type", db: "ORGType" }
    ], []);

    const [contractInitialValue, setContractInitialValue] = useState<any>({
        ReceiverOrgId: props?.initialSelectedOrg?.ORGId || '',
        Seed: '',
        SenderOrgId: ''
    });

    const validationSchema = yup.object().shape({
        ReceiverOrgId: yup.string().required('Receiver Organization ID is required'),
        Seed: yup.string().required('Seed is required'),
        SenderOrgId: yup.string().required('Sender Organization ID is required')
    });

    useEffect(() => {
        setContractInitialValue({
            ReceiverOrgId: props?.initialSelectedOrg?.ORGId || '',
            Seed: '',
            SenderOrgId: ''
        });
    }, [props.initialSelectedOrg]);


    // Sorting Data
    const onSortingData = useCallback((orderBy: any, order: any, data: any) => {
        if (orderBy && order && data) {
            setOrder(order === 'desc' ? -1 : 1);
            setOrderBy(orderBy);
            setOrderIndexedFields(data);
        }
    }, [])

    // Previous Page
    const goToPreviousPage = useCallback((page: any) => {
        setCurrentPageNo(page);
    }, [])

    useEffect(() => {
        if (prePage) {
            goToPreviousPage(prePage);
        }
    }, [prePage]);

    // Goto Next Page
    const goToNextPage = useCallback((page: any) => {
        setCurrentPageNo(page);
    }, [])

    useEffect(() => {
        if (nextPage) {
            goToNextPage(nextPage);
        }
    }, [nextPage]);


    // Change Page Size
    const changePageSize = useCallback((value: any) => {
        setPageSize(value);
        setCurrentPageNo(1);
    }, [])

    useEffect(() => {
        if (pageSize) {
            changePageSize(pageSize);
        }
    }, [pageSize]);


    // IsLessThenElement
    const isLessthanTotalElement = useCallback(() => {
        if ((currentPageNo * pageSize) === (totalPage - 1))
            return true;
        else
            return false;
    }, [currentPageNo, pageSize, totalPage])

    // memoizedFunctionApprove
    const memoizedFunctionApprove = useCallback((values: any) => { shareDataHandler(values) }, []);
    // viewCredentialMemoizedApprove
    const viewCredentialMemoizedApprove = useCallback((value: any) => setViewCredntialApp(value), []);


    // Modale Share Data
    const handleShare = useCallback(() => {
        setViewCredntialApp(false)
    }, []);



    //  Get Api Contract
    const getContract = (query, type?: string) => {
        props.toggleLoader(true);
        getContracts(query).then(res => {
            props.toggleLoader(false);
            setContractList(res.data.data);
            setTotalRecord(res.data.total);
            if (parseInt(res.data.total) % pageSize === 0) {
                setTotalPage((parseInt(res.data.total) / pageSize));
            }
            else {
                setTotalPage((Math.floor(parseInt(res.data.total) / pageSize)) + 1);
            }
            props.toggleLoader(false);
        }).catch(error => {
            props.toggleLoader(false);
            console.log(error, "Error data")
        })
    }

    useEffect(() => {
        if (pageSize && currentPageNo) {
            getContract({ pageNo: currentPageNo, pageSize: pageSize, IsDefaultOrg: false })
        }
    }, [pageSize, currentPageNo, props.forceUpdate]);


    useEffect(() => {
        if (pageSize && currentPageNo) {
            getContract({ pageNo: currentPageNo, pageSize: pageSize, searchKey: props.searchTextContract, IsDefaultOrg: false })
        }
    }, [props.searchTextContract]);

    // Export Data
    const exportDataJsonHandler = (values: any) => {
        const fileName = `${currentOrgDetails.ORGName} (${values.UID}).contract` || 'contract';
        const orgDataJson = {
            SenderOrgId: values.SenderOrgId,
            ReceiverOrgId: values.ReceiverOrgId,
            Seed: values.Seed,
            UID: values.UID,
            SenderOrgName: currentOrgDetails.ORGName
        }
        fileDownloadService.objectToJsonFile(orgDataJson, fileName);
    }

    // Share Handler
    const shareDataHandler = (contractDetails: any) => {
        props.handleContractSelection(contractDetails);
    }

    const initializeComponent = () => {
        props.toggleLoader(true);
        Promise.all([getCurrentOrganizationDetails(), getContracts({ IsDefaultOrg: false }), getUserGeneratedETIds({}), getDefaultOrganizations({ "IsDefaultOrg": false })]).then((responseList: any) => {
            if (responseList.length > 0 && responseList[0].data?.data?.length > 0) {
                setCurrentOrgDetails(responseList[0].data.data[0]);
                setContractInitialValue((prevValue: any) => {
                    return {
                        ...prevValue,
                        "senderOrganizationName": responseList[0].data.data[0]?.ORGName,
                        "SenderOrgId": responseList[0].data.data[0]?.ORGId,
                    }
                });
                setUserGeneratedETIds(responseList[2]?.data?.data);
                setAllORGLIST(responseList[3]?.data?.data);
            }
            props.toggleLoader(false);
        }).catch((errorList: any) => {
            props.toggleLoader(false);
            console.log(errorList, "values")
            if (errorList?.response?.data?.errors?.length > 0) {
                errorList.response.data.errors.forEach((response) => {
                    Toaster.errorToaster(response.message);
                });
              }
            
        })
    }


    useEffect(() => {
        initializeComponent();
    }, [forceUpdate]);


    const getCurrentOrganizationDetails = () => {
        return getDefaultOrganizations({ "IsDefaultOrg": true })
    }


    //handle Contract creation form
    const handleContractForm = async (values: any, formikHelpers: FormikHelpers<any>) => {
        await props.createOrganizationAndContract(values);
        setForceUpdate((prev) => !prev);
        props.setisVisisblepopup(false);
        formikHelpers.resetForm();
    }


    // Generate Seed

    const handleSeedGenerate = (setFieldValue: any) => {
        // props.toggleLoader(true);
        const secret = "";
        generateSeed(secret).then((res) => {
            props.toggleLoader(false);
            if (res.data?.data?.key && typeof res.data?.data?.key === 'string') {
                setFieldValue("SenderOrgId", currentOrgDetails?.ORGId);
                setFieldValue("Seed", res.data?.data?.key);
            }
        }).catch((error: AxiosError | Error) => {
            props.toggleLoader(false);
            console.log(error, "Error Data")
        })
    }
    return (
        <>
            <Sharing
                dataSource={contractList}
                columnList={tabColumnList}
                primaryColumn={"_id"}
                showEdit={false}
                showDelete={false}
                showView={false}
                showActions={true}
                keysToHide={['']}
                showUserEdit={true}
                showAdd={true}
                columnAutoWidth={true}
                isOrderIconChange={true}
                fromComponent={'UserComponent'}
                orderIndexedFields={orderIndexedFields}
                onSortingData={onSortingData}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                changePageSize={changePageSize}
                pageSize={pageSize}
                currentPageNo={currentPageNo}
                totalPage={totalPage}
                totalRecord={totalRecord}
                shareData={false}
                createContract={false}
                isLessthanTotalElement={isLessthanTotalElement()}
                viewCredentialApprove={viewCredentialMemoizedApprove}
                viewCredentialApproveData={memoizedFunctionApprove}
                viewCredentialExport={(value) => { }}
                viewCredentialExportData={exportDataJsonHandler}
            />
            <ModalBox
                visible={props.isVisiblePopup}
                onHiding={() => props.setisVisisblepopup(false)}
                showCloseButton={true}
                dragEnabled
                showTitle={true}
                title="Create Contract"
                className="create-contract-data"
                height={'auto'}
                width={500}
                onHidden={() => props.setisVisisblepopup(false)}
                wrapperAttr={{ class: 'create-contract-details' }}
            >
                <Formik
                    initialValues={contractInitialValue}
                    validationSchema={validationSchema}
                    onSubmit={handleContractForm}
                >
                    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, handleBlur }) => (
                        <form onSubmit={handleSubmit} className="dx-form">
                            <SelectWithLabel
                                inputLabel='Receiver Organization Name'
                                stylingMode='outlined'
                                placeholder='Receiver Organization Name'
                                dataSource={allORGList}
                                name={'ReceiverOrgId'}
                                displayExpr="ORGName"
                                valueExpr="ORGId"
                                value={values.ReceiverOrgId}
                                onItemClick={(event) => {
                                    setFieldValue("ReceiverOrgId", event.itemData.ORGId);
                                }}
                                error={touched.ReceiverOrgId && errors.ReceiverOrgId}
                                touched={touched.ReceiverOrgId}
                            />

                            <InputWithLabel
                                inputLabel='Receiver Organization Id'
                                stylingMode='outlined'
                                placeholder='Receiver Organization Id'
                                value={values.ReceiverOrgId}
                                name="ReceiverOrgId"
                                disabled
                                error={touched.ReceiverOrgId && errors.ReceiverOrgId}
                                touched={touched.ReceiverOrgId}
                            />

                            <InputWithLabel
                                inputLabel='Seed'
                                stylingMode='outlined'
                                placeholder="Generate Seed"
                                value={values.Seed}
                                name="Seed"
                                onFocusOut={(e: any) => { handleBlur(e.event) }}
                                onInput={(e: any) => handleChange(e.event)}
                                error={touched.Seed && errors.Seed}
                                touched={touched.Seed}
                                buttons={[
                                    {
                                        name: 'cancel',
                                        location: 'after',
                                        options: {
                                            onClick: () => handleSeedGenerate(setFieldValue),
                                            stylingMode: 'text',
                                            text: 'Generate',
                                            type: 'default'
                                        },
                                    },
                                ]}
                            />

                            <LabelText
                                inputLabel='Sender organization Id'
                                title={currentOrgDetails?.ORGId}
                            />

                            <LabelText
                                inputLabel='Sender organization Name'
                                title={currentOrgDetails?.ORGName}
                            />

                            <div className='contract-btn'>
                                <Button text="Close" className="btn-close" onClick={() => props.setisVisisblepopup(false)} />
                                <Button text="Create" useSubmitBehavior={true} type="default" className="btn-create" onClick={() => handleSubmit} />
                            </div>
                        </form>
                    )}
                </Formik>
            </ModalBox>
        </>
    )
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = () => (dispatch: any) =>
    bindActionCreators({ toggleLoader: toggleLoader }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ContractTable);


interface IContractTableProps {
    toggleLoader: any;
    activeTab: number;
    isVisiblePopup: boolean;
    setisVisisblepopup: React.SetStateAction<any>;
    initialSelectedOrg: any;
    handleContractSelection: (handleContractSelection: any) => void;
    createOrganizationAndContract: (dataObject: any, contractCase?: string) => Promise<void>;
    searchTextContract: any;
    setSearchTextContract: any;
    forceUpdate: boolean;
}
